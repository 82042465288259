import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { CSSProperties } from 'styled-components';

import CarouselDots from '../CarouselDots';
import useScrollEnd from 'src/hooks/useScrollEnd';

import styles from './styles.module.css';

interface CarouselProps {
  className?: string;
  items: unknown[];
  children: (item: unknown, index: number) => React.ReactElement;
  prefix?: string;
  indicator?: boolean;
  handleSwipeEvent?: (slide: number) => void;
}

const CarouselCss = ({
  className = '',
  items,
  children,
  prefix = '',
  indicator = false,
  handleSwipeEvent,
}: CarouselProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLOListElement>(null);

  const [current, setCurrent] = useState(0);

  const handleChange = (event: React.FormEvent<HTMLFormElement>) => {
    const target = event.target as HTMLInputElement;
    itemsRef.current?.style.setProperty('--page', target.value);
    const index = Number.isNaN(parseInt(target.value, 10)) ? 1 : parseInt(target.value, 10);
    setCurrent(index - 1);
  };

  const { x: scrollWidthPosition } = useScrollEnd(scrollerRef);
  const totalScrollWidth = scrollerRef.current?.scrollWidth ?? 0;

  useEffect(() => {
    const pageWidth = totalScrollWidth / items.length;
    const pageIndex = Math.round(scrollWidthPosition / pageWidth);
    if (!Number.isNaN(pageIndex)) {
      if (typeof handleSwipeEvent === 'function') {
        handleSwipeEvent(pageIndex);
      }
      setCurrent(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollWidthPosition, items]);

  return (
    <div
      className={cn(styles.carousel, className)}
      style={
        {
          '--total': items.length,
        } as CSSProperties
      }
    >
      <form className={styles.carouselPages} onChange={handleChange}>
        {items.map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${prefix}-page-${index}`}
            className={styles.carouselMarker}
            style={{ '--page': index } as React.CSSProperties}
          >
            {index > 0 ? (
              <label htmlFor={`${prefix}-page-${index}`} className={styles.carouselMarkerPrev}>
                <i className={styles.chevronLeft} />
              </label>
            ) : null}

            <input
              className={styles.carouselMarkerInput}
              type="radio"
              name="page"
              value={index + 1}
              id={`${prefix}-page-${index + 1}`}
              defaultChecked={index === 0}
            />

            <label htmlFor={`${prefix}-page-${index + 2}`} className={styles.carouselMarkerNext}>
              <i className={styles.chevronRight} />
            </label>
          </div>
        ))}
      </form>

      <div ref={scrollerRef} className={styles.carouselScroller}>
        <ol ref={itemsRef} className={styles.carouselItems}>
          {items.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={`item-${index}`} className={styles.carouselItem}>
                {children(item, index)}
              </li>
            );
          })}
        </ol>
      </div>

      {indicator ? <CarouselDots current={current} dots={items} /> : null}
    </div>
  );
};

export default CarouselCss;
